import {createBrowserRouter} from "react-router-dom";
import {Home} from "../app/home";
import {
    BOOKINGS, FORGOT_PASSWORD,
    LOGIN_ROUTE, PAYMENT,
    PRIVACY_ROUTE,
    PROFILE_ROUTE,
    SEARCH, SIGNUP_ROUTE,
    SUPPORT, TELEGRAM_LOGIN, TERMS_ROUTE,
    USER
} from "../components/routes/routeConsts"
import LoginPage from "../app/login";
import SupportPage from "../app/protected/profile/support";
import ProtectedLayout from "../app/protected/_layout";
import ProfileLayout from "../app/protected/profile/_layout";
import {Navigate} from "react-router";
import BookingsPage from "../app/protected/profile/bookings";
import UserPage from "../app/protected/profile/user";
import SearchPage from "../app/protected/profile/search";
import BookingInfoPage from "../app/protected/bookings/id";
import Location from "../app/protected/location/id";
import NotFound from "../app/notFound";
import Privacy from "../app/privacy";
import TermsOfUse from "../app/terms-of-use";
import SignupPage from "../app/signup";
import ForgotPasswordPage from "../app/forgot-password";
import PaymentPage from "../app/protected/profile/payment";
import {TelegramLoginPage} from "../app/TelegramLoginPage"

export const router = createBrowserRouter([
    {
        path: '',
        element: <Home />
    },
    {
        path: LOGIN_ROUTE,
        element: <LoginPage />
    },
    {
        path: TELEGRAM_LOGIN,
        element: <TelegramLoginPage />
    },
    {
        path: SIGNUP_ROUTE,
        element: <SignupPage />
    },
    {
        path: FORGOT_PASSWORD,
        element: <ForgotPasswordPage />
    },
    {
        path: SUPPORT,
        element: <SupportPage />
    },
    {
        path: PROFILE_ROUTE,
        element: <ProtectedLayout />,
        children: [
            {
                path: '',
                element: <ProfileLayout />,
                children: [
                    {
                        path: '',
                        element: <Navigate to={USER} />
                    },
                    {
                        path: BOOKINGS,
                        element: <BookingsPage />
                    },
                    {
                        path: USER,
                        element: <UserPage />
                    },
                    {
                        path: SEARCH,
                        element: <SearchPage />
                    },
                    {
                        path: `${BOOKINGS}/:id`,
                        element: <BookingInfoPage />
                    },
                    {
                        path: `${SEARCH}/:id`,
                        element: <Location />
                    },
                    {
                        path: `${PAYMENT}/:id`,
                        element: <PaymentPage />
                    }
                ]
            }
        ]
    },
    {

    },
    {
        path: PRIVACY_ROUTE,
        element: <Privacy />
    },
    {
        path: TERMS_ROUTE,
        element: <TermsOfUse />
    },
    {
        path: "*", element: <NotFound />
    }
])
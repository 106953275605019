import {ImSpinner2} from "react-icons/im"
import Footer from "../components/footer"
import {useFormik} from "formik"
import * as Yup from "yup"
import {
    MAX_FIRSTNAME_LETTERS,
    MIN_FIRSTNAME_LETTERS,
} from "../utils/validationConsts"
import {BOOKINGS, LOGIN_ROUTE, TELEGRAM_LOGIN} from "../components/routes/routeConsts"
import useAuthStore from "../store/useAuthStore"
import {useNavigate} from "react-router"
import {errorToast} from "../components/toasts/error"
import InputMask from 'react-input-mask'
import {FaTelegramPlane} from "react-icons/fa"

export default function SignupPage() {
    const {signup} = useAuthStore()
    const navigate = useNavigate()

    const handleLogoPressed = (e) => {
        e.preventDefault()
        navigate('/')
    }

    const handleLoginClick = (e) => {
        e.preventDefault()
        navigate(LOGIN_ROUTE)
    }

    const handleTelegramLogin = () => {
        navigate(TELEGRAM_LOGIN)
    }

    const formik = useFormik({
        initialValues: {
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            password: '',
            confirmPassword: ''
        },
        validationSchema: Yup.object({
            firstname: Yup.string().trim()
                .min(MIN_FIRSTNAME_LETTERS, 'Пожалуйста введите корректную фамилию')
                .max(MAX_FIRSTNAME_LETTERS, 'Слишком длинное имя')
                .required('Обязательное поле'),
            email: Yup.string().trim().email('Некорректный адрес электронной почты')
                .required('Обязательное поле'),
            phone: Yup.string().trim()
                .min(16, 'Введите телефон полностью')
                .matches(/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/, 'Неверный формат')
                .required('Обязательное поле'),
            password: Yup.string().trim()
                .min(8, 'Минимум 8 символов')
                .max(30, 'Слишком длинный пароль')
                .required('Обязательное поле'),
            confirmPassword: Yup.string().trim()
                .test('passwords-match', 'Пароли не совпадают', function (value) {
                    return this.parent.password === value
                })
        }),
        onSubmit: (values, {setSubmitting}) => {
            setSubmitting(true)
            signup({
                firstname: values.firstname,
                lastname: values.lastname,
                email: values.email,
                phone: values.phone,
                password: values.password
            }).then(() => {
                setSubmitting(false)
                navigate(BOOKINGS)
            }).catch(error => {
                setSubmitting(false)
                const res = error.response.data
                if (res) {
                    errorToast('Произошла ошибка: ' + res.ex)
                } else {
                    errorToast('Неизвестная ошибка: ' + error)
                }
            })
        }
    })

    return (
        <div className={'flex flex-col justify-center items-center min-h-screen md:ml-10'}>
            <div className={'flex flex-col items-center mb-2 w-80'}>
                <a href={'#'} onClick={handleLogoPressed} className={'text-4xl font-bold text-purple-500'}>Тезис</a>
                <p className={'text-lg'}>Создайте аккаунт</p>
                <button onClick={handleTelegramLogin} className={'bg-[#0088cc] rounded-md my-2 text-white p-3 w-full flex flex-row justify-center items-center'}>
                    <FaTelegramPlane size={24} className={'mr-2'} />
                    <p className={'font-medium'}>По номеру телефона</p>
                </button>
            </div>
            <form onSubmit={formik.handleSubmit} className={'w-80'}>
                <div>
                    <label>
                        <p>Имя и фамилия:</p>
                        <input id={'firstname'} type={'firstname'} value={formik.values.firstname}
                               onChange={formik.handleChange} onBlur={formik.handleBlur}
                               className={'border border-slate-300 p-2 duration-300 focus:shadow-xl rounded-lg w-full focus:border-purple-500 outline-none'}/>
                        {formik.touched.firstname && formik.errors.firstname ? (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.firstname}</div>
                        ) : null}
                    </label>
                </div>
                <div className={'mt-2'}>
                    <label>
                        <p>Почта:</p>
                        <input id={'email'} type={'email'} value={formik.values.email} onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               className={'border border-slate-300 p-2 duration-300 focus:shadow-xl rounded-lg w-full focus:border-purple-500 outline-none'}/>
                        {formik.touched.email && formik.errors.email ? (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
                        ) : null}
                    </label>
                </div>
                <div className={'mt-2'}>
                    <label>
                        <p>Номер телефона:</p>
                        <InputMask id={'phone'}
                                   type={'phone'}
                                   value={formik.values.phone}
                                   mask={'+7 (999) 999-99-99'}
                                   alwaysShowMask={true}
                                   onChange={formik.handleChange}
                                   onBlur={formik.handleBlur}
                                   className={'border border-slate-300 p-2 duration-300 focus:shadow-xl rounded-lg w-full focus:border-purple-500 outline-none'}/>
                        {formik.touched.phone && formik.errors.phone ? (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.phone}</div>
                        ) : null}
                    </label>
                </div>
                <div className={'mt-2'}>
                    <label>
                        <p>Пароль:</p>
                        <input id={'password'} type={'password'} value={formik.values.password}
                               onChange={formik.handleChange} onBlur={formik.handleBlur}
                               className={'border border-slate-300 p-2 duration-300 focus:shadow-xl rounded-lg w-full focus:border-purple-500 outline-none'}/>
                        {formik.touched.password && formik.errors.password ? (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.password}</div>
                        ) : null}
                    </label>
                </div>
                <div className={'mt-2'}>
                    <label>
                        <p>Подтвердите пароль:</p>
                        <input id={'confirmPassword'} type={'password'} value={formik.values.confirmPassword}
                               onChange={formik.handleChange} onBlur={formik.handleBlur}
                               className={'border border-slate-300 p-2 duration-300 focus:shadow-xl rounded-lg w-full focus:border-purple-500 outline-none'}/>
                        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.confirmPassword}</div>
                        ) : null}
                    </label>
                </div>
                <div className={'mt-4 flex justify-center'}>
                    <button type={'submit'} disabled={!formik.isValid || formik.isSubmitting}
                            className={'border bg-purple-500 rounded-lg w-full p-3 disabled:bg-purple-100'}>
                        <div className={'flex justify-center items-center'}>
                            {formik.isSubmitting ? <ImSpinner2 size={24} color={'white'} className={'animate-spin'}/> :
                                <p className={'text-white font-medium'}>Создать аккаунт</p>}
                        </div>
                    </button>
                </div>
                <div className={'flex flex-col items-center mb-5'}>
                    <button onClick={handleLoginClick}
                            className={'mt-2 w-full border border-purple-500 bg-white rounded-xl p-3'}>Войти в аккаунт
                    </button>
                </div>
            </form>
            <Footer/>
        </div>
    )
}
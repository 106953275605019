import {createWithEqualityFn} from "zustand/traditional";
import api from "../http/AutoHttp";
import {PAYMENT, SUBSCRIPTIONS} from "../utils/apiConsts";

const initialState = {
    subscriptions: null
}

const useSubscriptionStore = createWithEqualityFn((set, get) => {
    return {
        ...initialState,

        getSubscriptions: async () => {
            return new Promise((resolve, reject) => {
                api.get(SUBSCRIPTIONS)
                    .then(response => {
                        if (response.status === 200) {
                            set(state => ({
                                ...state,
                                subscriptions: response.data
                            }))
                            resolve()
                        } else {
                            let e = new Error()
                            e.response = response
                            throw e
                        }
                    })
                    .catch(e => {
                        console.log('error')
                        reject(e)
                    })
            })
        },

        getSubscription: async (id) => {
            return new Promise((resolve, reject) => {
                api.get(`${SUBSCRIPTIONS}/${id}`)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch(e => {
                        console.warn(e)
                        reject(e)
                    })
            })
        },

        createSubscription: async ({location, name, plate}) => {
            return new Promise((resolve, reject) => {
                let result = {
                    subscription: null,
                    payment: null
                }

                api.post(SUBSCRIPTIONS, {
                    name: name,
                    plate: plate,
                    locationId: location.id
                }).then(r => {
                    console.log(r)
                    if (r.status === 201) {
                        result = {
                            subscription: r.data,
                            payment: null
                        }

                        if (location.needPayment === 'FALSE') {
                            resolve(result)
                        }

                        api.post(PAYMENT, {
                            subscriptionId: r.data.id
                        }).then(r => {
                            result = {
                                ...result,
                                payment: r.data
                            }
                            resolve(result)
                        }).catch(e => {
                            reject(e)
                        })
                    } else {
                        reject(r)
                    }
                }).catch(e => {
                    reject(e)
                })
            })
        },

        createPayment: async ({subId}) => {
            return new Promise((resolve, reject) => {
                api.post(PAYMENT, {
                    subscriptionId: subId
                }).then(r => {
                    resolve(r.data)
                }).catch(e => {
                    reject(e)
                })
            })
        }
    }
})

export default useSubscriptionStore
import axios from 'axios'
import {AUTO_API} from "../utils/apiConsts"
import {RefreshToken} from "./utils";

const api = axios.create({
    withCredentials: true,
    baseURL: AUTO_API
});

api.interceptors.request.use(config => {
    const token = localStorage.getItem('accessToken')
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
    }
    config.headers['Content-Type'] = 'application/json'
    return config
},
error => {
    Promise.reject(error)
});

api.interceptors.response.use(response => {
    return response
}, async function (error) {
    return RefreshToken(error)
})

export default api
import React, {useEffect, useState} from "react";
import {CheckAutoServer} from "./http/checkServer";
import "react-toastify/dist/ReactToastify.css"
import useAuthStore from "./store/useAuthStore";
import {RouterProvider, useNavigate} from "react-router"
import useSubscriptionStore from "./store/useSubscriptionStore";
import {router} from "./utils/router";

const App = () => {
    const [autoServerAvailable, setAutoServerAvailable] = useState(false)
    const [isPageLoading, setPageLoading] = useState(true)
    const {getUser, checkAuth} = useAuthStore()
    const {getSubscriptions} = useSubscriptionStore()

    useEffect(() => {
        if (localStorage.getItem('accessToken')) {
            checkAuth()
                .then(() => {
                    getUser()
                })
                .catch(e => {
                    console.warn(e)
                })
                .finally(() => {
                    setPageLoading(false)
                })
        } else {
            setPageLoading(false)
        }

        CheckAutoServer()
            .then(() => {
                setAutoServerAvailable(true)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    if (!autoServerAvailable) {
        return (
            <>Сервер Тезис недоступен</> // TODO Not Available
        )
    }

    if (isPageLoading) {
        return (
            <></> // TODO Spinner
        )
    }

    return <RouterProvider router={router} />
}

export default App;
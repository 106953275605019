export const Colors = {
    icon: '#95a0b7',
    coloredIcon: '#9333ea'
}

const params = {
    default: {
        text: 'text-gray-800',
        bg: 'bg-white',
        icon: Colors.icon,
        tabIcon: Colors.icon,
        headingText: 'text-purple-600',
        hoverBg: 'hover:bg-gray-100',
        activeBg: 'active:bg-gray-200',
        navigationShadow: 'shadow-md'
    },
    colored: {
        text: 'text-white',
        bg: 'bg-purple-600',
        icon: '#fff',
        tabIcon: Colors.coloredIcon,
        headingText: 'text-white',
        hoverBg: 'hover:bg-purple-500',
        activeBg: 'active:bg-purple-400'
    }
}

export const getColor = (param, isColored) => {
    return params[isColored ? 'colored' : 'default'][param]
}
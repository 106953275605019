import useAuthStore from "../../../store/useAuthStore";
import {useEffect, useState} from "react";
import {errorToast} from "../../../components/toasts/error";
import {useFormik} from "formik";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import {
    MAX_FIRSTNAME_LETTERS,
    MIN_FIRSTNAME_LETTERS,
} from "../../../utils/validationConsts";
import {ImSpinner2} from "react-icons/im";
import Loading from "../../../components/loading";

export default function UserPage() {
    const {user, getUser, updateUser} = useAuthStore()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!user) {
            setLoading(true)
            getUser()
                .then(() => {
                })
                .catch(e => {
                    const res = e.response?.data
                    if (res) {
                        errorToast(res.ex)
                        //setError(res.ex)
                    } else {
                        errorToast('Неизвестная ошибка')
                        //setError(null)
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [])

    const formik = useFormik({
        initialValues: {
            firstname: user.firstname,
            email: user.email,
            phone: user.phone
        },
        validationSchema: Yup.object({
            firstname: Yup.string()
                .trim()
                .min(MIN_FIRSTNAME_LETTERS, 'Слишком короткое имя')
                .max(MAX_FIRSTNAME_LETTERS, 'Слишком длинное имя')
                .required('Не может быть пустым'),
            email: Yup.string()
                .trim()
                .email()
                .required('Не может быть пустым'),
            phone: Yup.string().trim()
                .matches(/^7\d{10}$/, 'Неверный формат')
                .required('Обязательное поле')
        }),
        onSubmit: (values,{ setSubmitting }) => {
            setSubmitting(true)
            console.log('onSubmit')
            updateUser({
                firstname: values.firstname,
                email: values.email,
                phone: values.phone
            })
                .then((response) => console.log(response))
                .catch(e => errorToast('Ошибка: ' + e.response.data.ex))
                .finally(() => setSubmitting(false))
        }
    })

    if (loading || !user) {
        return <Loading />
    }

    return (
        <div className={'p-4 lg:ml-10'}>
            <p className={'text-2xl'}>Личные данные</p>

            <form className={'w-80'} onSubmit={formik.handleSubmit}>
                <input id={'firstname'} value={formik.values.firstname} onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       placeholder={'Имя и фамилия'}
                       className={'w-full mt-3 p-4 rounded-xl border outline-0 focus:border-purple-500'}/>
                {formik.touched.firstname && formik.errors.firstname ? (
                    <div className="text-red-500 text-sm mt-1">{formik.errors.firstname}</div>
                ) : null}
                <input id={'email'} value={formik.values.email} onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       placeholder={'Email'}
                       className={'w-full mt-3 p-4 rounded-xl border outline-0 focus:border-purple-500'}/>
                {formik.touched.email && formik.errors.email ? (
                    <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
                ) : null}

                <InputMask
                    id="phone"
                    mask={'79999999999'}
                    alwaysShowMask={true}
                    name="phone"
                    placeholder={'Номер телефона'}
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                    className={`w-full mt-3 p-4 rounded-xl border outline-0 focus:border-purple-500 ${formik.touched.phone && formik.errors.phone ? 'border-red-500' : 'border-gray-300'}`}
                />
                {formik.touched.phone && formik.errors.phone ? (
                    <div className="text-red-500 text-sm mt-1">{formik.errors.phone}</div>
                ) : null}

                <button type={'submit'}
                        className={`mt-4 w-full flex justify-center p-4 rounded-xl bg-purple-500 text-white font-medium disabled:cursor-not-allowed disabled:bg-purple-100`}
                        disabled={!formik.isValid || formik.isSubmitting}>
                    {!formik.isSubmitting
                        ? 'Сохранить'
                        : <ImSpinner2 size={24} className={'text-center animate-spin'}/>
                    }
                </button>
            </form>
        </div>
    )
}
import useAuthStore from "../store/useAuthStore";
import {useLocation, useNavigate} from "react-router";
import {PROFILE_ROUTE} from "../components/routes/routeConsts";
import {useEffect} from "react";
import {errorToast} from "../components/toasts/error";

export default function NotFound() {
    const { accessToken } = useAuthStore()
    const navigate = useNavigate()
    const {pathname} = useLocation()

    useEffect(() => {
        errorToast('404. Ресурс не найден:' + pathname)
        if (accessToken) {
            navigate(PROFILE_ROUTE)
        } else {
            navigate('/')
        }
    }, [])
}
export default function ApiError({message}) {
    return (
        // <AnimatedPage>
        //     <Flex justify={'center'} align={'center'} width={'100%'} h={'100vh'}>
        //         <VStack>
        //             <Text fontSize={'28px'}>
        //                 Ошибка :(
        //             </Text>
        //             <VStack>
        //                 <Text>{message}</Text>
        //                 <Text align={'center'}>
        //                     <Link href={ID_ROUTE} color={'blue.400'}>Вернуться на главную</Link>
        //                 </Text>
        //             </VStack>
        //         </VStack>
        //     </Flex>
        // </AnimatedPage>
        <></> // TODO ApiError
    )
}
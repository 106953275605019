export default function Privacy() {
  return (
      <div className={'flex justify-center my-10'}>
        <div className={'container'}>
            <p className={'font-bold text-2xl'}>Политика в отношении обработки персональных данных</p>
            <br/>
            <p className={'font-bold text-xl'}>1. ОБЩИЕ ПОЛОЖЕНИЯ</p>
            <p>1.1. Настоящая Политика конфиденциальности (далее – <strong>«Политика»</strong>) издана и применяется ИП Пиняскин И. С. (далее – <strong>«Разработчик Тезис»</strong>).</p>
            <p>1.2. Предметом регулирования настоящей Политики являются отношения между ИП, которое является правообладателем сервиса Тезис (далее <strong>«Сервис»</strong>, и Вами, как пользователем Сервиса, по поводу обработки предоставляемой Вами в процессе использования Сервиса информации, в том числе персональных данных (далее - <strong>«Информация»</strong>).</p>
            <p>1.3. Настоящая Политика разработана и применяется совместно с Пользовательским соглашением.</p>
            <p>1.4. Регистрация и использование Вами Сервиса свидетельствует о Вашем полном и безусловном согласии с условиями настоящей Политики, в редакции, которая действовала на момент фактического использования Сервиса.</p>
            <p>1.5. Настоящая Политика действует и регулируется в соответствии с настоящим законодательством, действующим на территории Российской Федерации</p>
            <br />
            <p className={'font-bold text-xl'}>2. КАКУЮ ИНФОРМАЦИЮ О ВАС СОБИРАЕТ РАЗРАБОТЧИК ТЕЗИС</p>
            <p>2.1. Разработчик Тезис собирает о Вас следующую Информацию:</p>
            <p>2.1.1. персональные данные, а именно:</p>
            <p>1) данные, предоставляемые Вами при регистрации в Сервисе (создании учетной записи), а именно: имя; фамилия; адрес электронной почты; номер мобильного телефона; марка, модель, а также государственный номер автомобиля</p>
            <p>2) данные, которые становятся доступными Разработчику Тезис в случае приобретении абонемента или оплаты доступа через Сервис; а также</p>
            <p>3) данные, которые Вы предоставляете по запросу Разработчика Тезис, направляемому в случаях, предусмотреннах действующим законодательством Российской Федерации (в частности, для целей пресечения нарушения прав и законных интересов третьих лиц).</p>
            <p>2.1.2. иные данные, не являющиеся персональными данными:</p>
            <p>1) электронные данные, а именно: HTTP-заголовки; IP-адрес; данные об идентификаторе браузера; информация об аппаратном и программном обеспечении; токен устройства (для отправки push-уведомлений);</p>
            <p>2) дата и время осуществления Вашего доступа к Сервису;</p>
            <p>3) данные о геолокации; а также</p>
            <p>4) данные о Ваших действиях в Сервисе (в частности, информация о разделах Сервиса, которые Вы посещали).</p>
            <br />
            <p className={'font-bold text-xl'}>3. В КАКИХ ЦЕЛЯХ РАЗРАБОТЧИК ТЕЗИС ОБРАБАТЫВАЕТ ВАШУ ИНФОРМАЦИЮ</p>
            <p>3.1. Разработчик Тезис осуществляет обработку Вашей информации в целях:</p>
            <p>1) надлежащего исполнения Разработчика Тезис своих обязательств перед Вами;</p>
            <p>2) совершенствования Сервиса и его функциональных возможностей; а также</p>
            <p>3) информирования Вас о развитии Сервиса и его функциональных возможностях.</p>
            <p>4) согласно требованиям Федерального закона "О персональных данных" №152</p>
            <br />
            <p className={'font-bold text-xl'}>4. КАКИМ ОБРАЗОМ РАЗРАБОТЧИК ТЕЗИС ОСУЩЕСТВЛЯЕТ ОБРАБОТКУ И ПЕРЕДАЧУ ВАШЕЙ ИНФОРМАЦИИ</p>
            <p className={'font-bold'}>4.1. Каким образом осуществляется сбор Информации</p>
            <p>4.1.1. Сбор Ваших персональных данных, указанных в подпунктах 1) и 2) пункта 2.1.1. настоящей Политики, осуществляется при регистрации в Сервисе путем заполнения Вами регистрационной формы или иными доступными способами, а также в дальнейшем при редактировании Вами ранее предоставленных персональных данных. Эти персональные данные являются минимально необходимыми для регистрации и использования Сервиса.</p>
            <p>Персональные данные, предусмотренные подпунктом 3) пункта 2.1.1. настоящей Политики, предоставляются Вами в исключительных случаях при получении соответствующего запроса от Разработчика Тезис.</p>
            <p>4.1.2. Сбор иных данных, не являющихся персональными данными, осуществляется Разработчиком Тезис самостоятельно в процессе использования Вами Сервиса.</p>
            <p className={'font-bold'}>4.2. Каким образом осуществляется обработка и передача Вашей Информации</p>
            <p>4.2.1. Ваша Информация не передается каким-либо третьим лицам, за исключением случаев, прямо предусмотренных настоящей Политикой, а также действующим законодательством Российской Федерации.</p>
            <p>4.2.2. Паркли вправе осуществлять передачу Вашу Информацию, включая персональные данные, третьим лицам, являющимся контрагентами Паркли. Предоставление Информации контрагентам Разработчика Тезис возможна при условии, что передача Информации необходима для обеспечения функционирования Сервиса в Ваших интересах, а также при условии, что такими контрагентами приняты обязательства по обеспечению конфиденциальности полученной Информации.</p>
            <p>4.3. Каким образом и как долго хранится Ваша информация</p>
            <p>4.3.1. Ваша Информация хранится на территории Российской Федерации, при этом хранение осуществляется исключительно на электронных носителях, а обработка – с использованием автоматизированных систем, за исключением случаев, когда неавтоматизированная обработка Информации необходима в связи с исполнением требований действующего законодательства Российской Федерации.</p>
            <p>4.3.2. Ваша Информация хранится у Разработчика Тезис в течение срока действия соглашения с Вами о предоставлении права использования Сервиса, а после прекращения действия соглашения – в течение срока, установленного действующим законодательством Российской Федерации.</p>
            <p className={'font-bold'}>4.4. Когда прекращается обработка Информации</p>
            <p>4.4.1. По достижении целей обработки Информации Разработчик Тезис прекращает обработку Информации одним из способов, предусмотренных Федеральным законом от 27 июля 2006 г. № 152-ФЗ «О персональных данных».</p>
            <br />
            <p className={'font-bold text-xl'}>5. ВАШИ ПРАВА И ОБЯЗАННОСТИ</p>
            <p>5.1. Вы вправе:</p>
            <p>1) осуществлять свободный бесплатный доступ к размещенным Вами персональным данным посредством ознакомления с такими данными в Вашем личном кабинете в Сервисе;</p>
            <p>2) самостоятельно редактировать свои персональные данные в своем личном кабинете в Сервисе, при условии, что такие изменения (или) дополнения являются актуальными и достоверными;</p>
            <p>3) требовать от Разработчика Тезис уточнения Ваших персональных данных, их блокирования или уничтожения в случае, если такие данные являются неполными, устаревшими, недостоверными, необоснованно полученными или не являются необходимыми для заявленной цели обработки и если функционал Сервиса не позволяет Вам самостоятельно удалить такие данные; а также</p>
            <p>4) на основании запроса получать от Разработчика Тезис информацию, касающуюся обработки Ваших персональных данных.</p>
            <br />
            <p className={'font-bold text-xl'}>6. КАКИЕ МЕРЫ ПРЕДПРИНИМАЕТ РАЗРАБОТЧИК ТЕЗИС ДЛЯ ЗАЩИТЫ ВАШЕЙ ИНФОРМАЦИИ</p>
            <p>6.1. Разработчик Тезис принимает необходимые правовые, организационные и технические меры для защиты Вашей Информации от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения Информации, а также от иных неправомерных действий в отношении Информации.</p>
            <p>6.2. Технические меры безопасности реализованы Разработчик Тезис с учетом требований действующего законодательства Российской Федерации, современного уровня техники, характера обрабатываемой информации и рисков, связанных с ее обработкой.</p>
            <p>6.3. Информация обрабатывается преимущественно автоматически без доступа к ней работников и (или) контрагентов Разработчика Тезис. В случае если такой доступ предоставляется работникам и (или) контрагентам Разработчика Тезис, то только в объеме, необходимом для выполнения такими лицами своих служебных обязанностей или обязанностей по договору с Разработчиком Тезис, при этом на таких лиц возлагается обязанность по соблюдению требований безопасности при осуществлении доступа к Информации. Для защиты и обеспечения конфиденциальности Информации все сотрудники и контрагенты соблюдают внутренние правила и процедуры в отношении обработки Информации. Указанные лица также соблюдают все технические и организационные меры безопасности, предусмотренные действующим законодательством Российской Федерации и необходимые для защиты Вашей Информации.</p>
            <br />
            <p className={'font-bold text-xl'}>7. ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ РАЗРАБОТЧИКА ТЕЗИС</p>
            <p>7.1. Разработчик Тезис не несет ответственности за разглашение и распространение Вашей Информации третьими лицами в случае, если такие лица получили доступ к указанной Информации вследствие необеспечения Вами сохранности устройств и данных, используемых для авторизации в Сервисе.</p>
            <br />
            <p className={'font-bold text-xl'}>8. ИЗМЕНЕНИЕ И (ИЛИ) ДОПОЛНЕНИЕ ПОЛИТИКИ</p>
            <p>8.1. Разработчик Тезис вправе в одностороннем порядке вносить изменения и (или) дополнения в настоящую Политику. Паркли обязуется не вносить существенных изменений и (или) дополнений в настоящую Политику без Вашего уведомления. Продолжение использования Вами Сервиса после внесения изменений и (или) дополнений в настоящую Политику означает Ваше полное и безусловное согласие с такими изменениями и (или) дополнениями.</p>
            <br />
            <p className={'font-bold text-xl'}>9. ВОПРОСЫ И ПРЕДЛОЖЕНИЯ</p>
            <p>9.1. Разработчик Тезис приветствует Ваши вопросы и предложения, касающиеся настоящей Политики.</p>
            <p>10.2. Сведения об обрабатываемой Разработчиком Тезис Информации в связи с использованием Сервиса предоставляются Вам или Вашему уполномоченному представителю при обращении (запросе).</p>
            <br />
            <p className={'italic'}>Редакция политики от "26" мая 2024 г.</p>
        </div>
      </div>
  )
}
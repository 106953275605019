// export const AUTO_API = 'http://localhost:8082/api/v1'
// export const AUTO_API = 'http://thesispark.ru:8082/api/v1'
export const AUTO_API = process.env.REACT_APP_THESIS_API
//export const AUTH_API = 'http://5.35.90.145:9999/api/v1'

export const REFRESH_TOKEN = '/auth/refresh-token'
export const USER_INFO = '/clients/me'
export const USER_UPDATE = '/clients/update'

export const AUTH_LOGIN = '/auth/authenticate'
export const AUTH_TG = '/auth/telegram'
export const AUTH_REGISTER = '/auth/register'

export const GET_ALL_LOCATIONS = '/locations'

// -------- v2 ---------
export const SUBSCRIPTIONS = '/subscriptions'
export const PAYMENT = '/payment'
export const LOCATIONS = '/locations'
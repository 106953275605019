import {useNavigate, useParams} from "react-router";
import {errorToast} from "../../../components/toasts/error";
import {BOOKINGS, SEARCH} from "../../../components/routes/routeConsts";
import ApiError from "../../apiError";
import Loading from "../../../components/loading";
import * as Yup from 'yup'

import {FaArrowLeft} from "react-icons/fa";
import {useEffect, useState} from "react";
import useLocationStore from "../../../store/useLocationStore";
import {Colors} from "../../../utils/colors";

import InputMask from "react-input-mask";
import {useFormik} from "formik";
import useSubscriptionStore from "../../../store/useSubscriptionStore";

export default function Location() {
    const {id} = useParams()
    const {getLocation} = useLocationStore()
    const {createSubscription} = useSubscriptionStore()
    const [location, setLocation] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const navigate = useNavigate()
    const [isOpen, setOpen] = useState(false)

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true
    }

    useEffect(() => {
        setLoading(true)
        getLocation(id)
            .then((location) => {
                console.log(location)
                setLocation(location)
            })
            .catch(e => {
                const res = e.response?.data
                if (res) {
                    errorToast(res.ex)
                    setError(res.ex)
                } else {
                    errorToast('Неизвестная ошибка')
                    setError('Неизвестная ошибка')
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    const formik = useFormik({
        initialValues: {
            plate: '',
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .min(2)
                .max(20)
                .required('Обязательно'),
            plate: Yup.string()
                .matches(/^[ABEKMHOPCTYXАВЕКМНОРСТУХ]{1}\d{3}[ABEKMHOPCTYXАВЕКМНОРСТУХ]{2}\d{2,3}$/, 'Некорректный формат')
                .required('Обязательно')
        }),
        onSubmit: (values, {setSubmitting}) => {
            setSubmitting(true)
            createSubscription({
                location: location,
                name: values.name,
                plate: values.plate
            })
                .then(r => {
                    if (!r.payment) {
                        navigate(BOOKINGS)
                    } else {
                        window.location.replace(r.payment.paymentLink)
                    }
                })
                .catch(e => {
                    console.error(e)
                    errorToast(e.response?.data?.ex)
                })
                .finally(() => {
                    setSubmitting(false)
                })
        }
    })

    // ---------- HANDLERS ---------------

    const handleBackButton = () => {
        navigate(SEARCH)
    }

    const handleMapLinkParam = () => {
        window.open(location.mapLink, '_blank')
    }

    const handleBookButton = () => {
        setOpen(true)
    }

    const convertLocationType = (type) => {
        switch (type) {
            case 'OPEN':
                return 'Открытая парковка'
            case 'UNDERGROUND':
                return 'Паркинг'
            case 'COVERED':
                return 'Крытая парковка'
            default:
                return 'нет данных'
        }
    }

    if (error) {
        return (
            <ApiError message={error}/>
        )
    }

    if (loading || !location) {
        return (
            <Loading/>
        )
    }

    const onClose = (e) => {
        e.preventDefault()
        setOpen(false)
    }

    const freeSpots = location.freeSpots === -1 ? 'Не ограничено' : location.freeSpots

    return (
        <div className={'p-4 lg:ml-10'}>
            <div className={'flex items-center  mb-5'}>
                <button onClick={handleBackButton} className={'mr-5'}><FaArrowLeft size={20} color={Colors.icon}/>
                </button>
                <p className={'text-2xl'}>Парковка</p>
            </div>
            <div className={'flex flex-col p-5 w-96 mt-2 h-fit rounded-xl shadow-thesis divide-y'}>
                <div className={'pb-2'}>
                    <p className={'font-medium text-lg'}>{location.name}</p>
                    <p>{location.address}</p>
                </div>
                <div className={'py-2'}>
                    <img src={location.imageUrls[0]} alt={location.name} className={'rounded-md'}/>
                </div>
                <div className={'py-2'}>
                    <p>{location.description}</p>
                </div>
                <div className={'pt-2'}>
                    {(location.needPayment === 'TRUE') && (
                        <div className={'flex flex-row justify-between mb-1'}>
                            <p>Стоимость</p>
                            <p>{location.cost}</p>
                        </div>
                    )}
                    <div className={'flex flex-row justify-between mb-1'}>
                        <p>Тип</p>
                        <p>{convertLocationType(location.locationType)}</p>
                    </div>
                    {(location.freeSpots > -1) && (
                        <div className={'flex flex-row justify-between mb-1'}>
                            <p>Свободных мест</p>
                            <p>{location.freeSpots}</p>
                        </div>
                    )}
                    <div className={'flex flex-row justify-between mb-1'}>
                        <p>Яндекс.Карты</p>
                        <a onClick={handleMapLinkParam} className={'underline text-purple-500 cursor-pointer'}>открыть</a>
                    </div>
                    <button onClick={handleBookButton}
                            className={'w-full p-3 mt-2 rounded-xl bg-purple-500 text-white font-medium'}>
                        {location.protectedStatus === 'PROTECTED' ? 'Подать заявку' : 'Забронировать'}
                    </button>
                </div>
            </div>
            {isOpen && (
                <div className={'fixed inset-0 flex items-center justify-center z-50'}>
                    <div className={'fixed inset-0 bg-black bg-opacity-50'} onClick={onClose}></div>
                    <div
                        className={'bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-lg w-full'}>
                        <div className={'p-4 divide-y'}>
                            <div>
                                <p className={'text-xl font-medium mb-1'}>{location.protectedStatus === 'PROTECTED' ? 'Новая заявка' : 'Бронирование'}</p>
                            </div>
                            <div>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className={'mt-1'}>
                                        <p>Марка:</p>
                                        <input id={'name'}
                                               placeholder={'Toyota Camry'}
                                               className={`block outline-none w-full p-2 border rounded ${formik.touched.carNumber && formik.errors.carNumber ? 'border-red-500' : ''} focus:border-purple-500`}
                                               onChange={formik.handleChange}
                                               onBlur={formik.handleBlur}
                                               value={formik.values.name} />
                                        {formik.touched.name && formik.errors.name ? (
                                            <div className="text-red-500 text-sm mt-1">{formik.errors.plate}</div>
                                        ) : null}
                                    </div>
                                    <div className={'mt-1'}>
                                        <p>Номер автомобиля (заглавными на латинице):</p>
                                        <InputMask id={'plate'}
                                                   mask={'a999aa999'}
                                                   maskChar={''}
                                                   placeholder={'A111AA196'}
                                                   alwaysShowMask={false}
                                                   onChange={formik.handleChange}
                                                   onBlur={formik.handleBlur}
                                                   value={formik.values.plate}
                                                   className={`block w-full p-2 border outline-none rounded ${formik.touched.carNumber && formik.errors.carNumber ? 'border-red-500' : ''} focus:border-purple-500`}/>
                                        {formik.touched.plate && formik.errors.plate ? (
                                            <div className="text-red-500 text-sm mt-1">{formik.errors.plate}</div>
                                        ) : null}
                                    </div>
                                    {(location.needPayment === 'TRUE') && (
                                        <div className={'py-2 flex flex-row justify-between'}>
                                            <p className={'font-medium text-lg'}>К оплате</p>
                                            <p className={'font-medium text-lg'}>{location.cost} руб</p>
                                        </div>
                                    )}
                                    <div className={'mt-3 flex flex-row justify-center w-full'}>
                                        <button type={'submit'}
                                                disabled={!formik.isValid || formik.isSubmitting}
                                                className={'p-2 bg-purple-500 text-white rounded-xl font-medium disabled:bg-purple-200'}>
                                            {location.needPayment === 'FALSE' ? 'Отправить' : 'Оплатить'}
                                        </button>
                                        <button className={'p-2 ml-2 rounded-xl border-purple-500 border'}
                                                onClick={onClose}>
                                            Отмена
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
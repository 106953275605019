import Navigation from "../../../components/navigation";
import Footer from "../../../components/footer";

export default function SupportPage() {
    const handleClick = (e) => {
        e.preventDefault()
        window.open('https://t.me/ThesisSupportBot', '_blank')
    }

    return (
        <div className={'flex flex-col items-center'}>
            <Navigation isColored={true} />
            <div className={'flex justify-center pt-20 w-full flex-1 mb-5'}>
                <div className={'container py-5 flex flex-col items-center'}>
                    <p className={'text-2xl font-medium text-center'}>Поддержка</p>
                    <div className={'mt-5 w-96'}>
                        <p>Поддержка Тезис доступна через бота в <a className={'underline text-purple-500 cursor-pointer'} onClick={handleClick} href={'#'}>Telegram</a>.
                            Оставьте заявку и мы ответим в ближайшее время</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
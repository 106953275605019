import {useNavigate} from "react-router";
import {PRIVACY_ROUTE, TERMS_ROUTE} from "./routes/routeConsts";

export default function Footer() {
    const navigate = useNavigate()

    return (
        <div className={'flex justify-center'}>
            <div className={'container p-6 border-t flex flex-col lg:flex-row'}>
                <p className={'mr-4'}>&#xA9; 2024 Пиняскин И. С.</p>
                <a className={'mr-4 underline'} href={'#'} onClick={(e) => {
                    e.preventDefault()
                    navigate(PRIVACY_ROUTE)
                }}>Политика конфиденциальности</a>
                <a className={'mr-4 underline'} href={'#'} onClick={(e) => {
                    e.preventDefault()
                    navigate(TERMS_ROUTE)
                }}>Пользовательское соглашение</a>
            </div>
        </div>
    )
}
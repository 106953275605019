import {useNavigate, useParams} from "react-router";
import useSubscriptionStore from "../../../store/useSubscriptionStore";
import {useEffect, useState} from "react";
import {errorToast} from "../../../components/toasts/error";
import Loading from "../../../components/loading";
import AcceptProps from "../../../utils/AcceptProps";
import {FaArrowLeft} from "react-icons/fa";
import {Colors} from "../../../utils/colors";
import {BOOKINGS} from "../../../components/routes/routeConsts";

export default function BookingInfoPage() {
    const {id} = useParams()
    const {getSubscription, createPayment} = useSubscriptionStore()
    const [subscription, setSubscription] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        getSubscription(id)
            .then((subscription) => {
                console.log(subscription)
                setSubscription(subscription)
            })
            .catch(e => {
                const res = e.response?.data
                if (res) {
                    errorToast(res.ex)
                    setError(res.ex)
                } else {
                    errorToast('Неизвестная ошибка')
                    setError(null)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    const handleBackButton = () => {
        navigate(BOOKINGS)
    }

    const handleExtendButton = () => {
        createPayment({subId: id})
            .then(r => {
                window.location.replace(r.paymentLink)
            })
            .catch(e => {
                errorToast(e)
            })
    }

    if (loading || !subscription) {
        return (
            <Loading/>
        )
    }

    const accept = new AcceptProps(subscription.acceptStatus)

    const paidTo = `${subscription.paidTo[2]}.${subscription.paidTo[1]}.${subscription.paidTo[0]}`

    return (
        <div className={'p-4 lg:ml-10'}>
            <div className={'flex items-center  mb-5'}>
                <button onClick={handleBackButton} className={'mr-5'}><FaArrowLeft size={20} color={Colors.icon}/>
                </button>
                <p className={'text-2xl'}>Бронирование</p>
            </div>
            <div className={'flex flex-col p-5 w-96 mt-2 h-fit rounded-xl shadow-thesis divide-y'}>
                <div className={'pb-2'}>
                    <p className={'font-medium text-lg'}>{subscription.location.name}</p>
                    <p>{subscription.location.address}</p>
                </div>
                <div className={'py-2'}>
                    <div className={'flex flex-row justify-between mb-1'}>
                        <p>Автомобиль</p>
                        <p>{subscription.name}</p>
                    </div>
                    <div className={'flex flex-row justify-between'}>
                        <p>Номер авто</p>
                        <p>{subscription.plate}</p>
                    </div>
                </div>
                {(subscription.location.needPayment === 'TRUE') && (
                    <>
                        {subscription.paymentStatus === 'PAID'
                            ? (
                                <div className={'py-2 flex justify-between text-green-500'}>
                                    <p>Истекает</p>
                                    <p>{paidTo}</p>
                                </div>
                            )
                            : (
                                <div className={'py-2 flex justify-between text-red-500'}>
                                    <p>Истекло</p>
                                    <p>{paidTo}</p>
                                </div>
                            )
                        }
                    </>
                )}
                {subscription.location.protectedStatus === 'PROTECTED' && (
                    <div className={`py-2 flex justify-between`}>
                        <p>Статус</p>
                        <p className={`${accept.color}`}>{accept.text}</p>
                    </div>
                )}
                {(subscription.location.needPayment === 'TRUE') && (
                    <>
                        <p className={'text-xl text-center p-3'}>Покажите этот талон охране</p>
                        <button onClick={handleExtendButton}
                                className={'bg-purple-500 text-white font-medium p-3 rounded-xl'}>
                            Продлить
                        </button>
                    </>
                )}
            </div>
        </div>
    )
}
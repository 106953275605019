import {useEffect, useState} from "react";
import useSubscriptionStore from "../../../store/useSubscriptionStore";
import Loading from "../../../components/loading";
import AcceptProps from "../../../utils/AcceptProps";
import {BOOKINGS, SEARCH, SUBSCRIPTION} from "../../../components/routes/routeConsts";
import {useNavigate} from "react-router";

function SubscriptionCard({sub}) {
    const navigate = useNavigate()

    const accept = new AcceptProps(sub.acceptStatus)
    const paid = sub.paymentStatus === 'PAID'
    const paidTo = `${sub.paidTo[2]}.${sub.paidTo[1]}.${sub.paidTo[0]}`

    const handleClickSubscription = () => {
        navigate(`${BOOKINGS}/${sub.id}`)
    }

    return (
        <div onClick={handleClickSubscription} className={'cursor-pointer my-4 p-4 shadow-thesis hover:shadow-xl duration-300 rounded-xl min-w-80'}>
            <p className={'font-medium'}>{sub.location.name}</p>
            <p className={'text-sm'}>{sub.name}</p>
            <p className={'text-sm'}>{sub.location.address}</p>
            {sub.location.needPayment === 'FALSE'
                ? (
                    <p className={`${accept.color}`}>{accept.text}</p>
                )
                : (
                    <p className={`${paid ? 'text-green-500' : 'text-red-500'}`}>
                        {paid ? 'истекает' : 'требуется оплата'} {paid ? paidTo : ''}
                    </p>
                )
            }
        </div>
    )
}

export default function BookingsPage() {
    const [loading, setLoading] = useState(false)
    const {subscriptions, getSubscriptions} = useSubscriptionStore()
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        if (!subscriptions) {
            getSubscriptions()
                .then(r => {
                    console.log(subscriptions)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
        setLoading(false)
    }, [])

    if (loading || !subscriptions) {
        return (
            <Loading />
        )
    }

    const handleClickLink = (e) => {
        e.preventDefault()
        navigate(SEARCH)
    }

    if (subscriptions.length === 0) {
        return (
            <div className={'p-4 md:ml-10'}>
                <p className={'text-2xl'}>Бронирования</p>
                <div className={'mt-2'}>
                    <p>У вас еще нет бронирований</p>
                    <p>Перейдите во вкладку <a onClick={handleClickLink} href={'#'} className={'text-purple-500'}>Поиск парковок</a></p>
                </div>
            </div>
        )
    }

    return (
        <div className={'p-4 md:ml-10'}>
            <p className={'text-2xl'}>Бронирования</p>
            <div>
                {subscriptions && subscriptions.map(sub => (
                    <SubscriptionCard sub={sub}/>
                ))}
            </div>
        </div>
    )
}
import Navigation from "../components/navigation";
import {Colors} from "../utils/colors";
import {FaClock, FaRegBookmark} from "react-icons/fa";
import {SiAdguard} from "react-icons/si";
import {LuParkingSquare} from "react-icons/lu";
import {HiCursorClick} from "react-icons/hi";
import {IoSearchSharp} from "react-icons/io5";
import {useNavigate} from "react-router";
import {SEARCH} from "../components/routes/routeConsts";
import Footer from "../components/footer";

export function Home() {
    const navigate = useNavigate()

    return (
        <div className={'min-h-screen flex flex-col'}>
            <Navigation isColored={true}/>
            <div className={'flex-1'}>
                <div className={'flex justify-center bg-purple-600 pt-20 pb-5 text-white'}>
                    <div className={'container flex flex-col items-center w-full'}>
                        <p className={'text-3xl text-center font-medium'}>Поиск и аренда парковок в Екатеринбурге</p>
                        <button onClick={() => navigate(SEARCH)} className={'bg-white text-black mt-7 text-lg mt-3 w-48 px-4 py-2 rounded-lg hover:text-gray-600'}>
                            Найти парковку
                        </button>
                    </div>
                </div>
                <div className={'divide-y'}>
                    <div className={'flex justify-center m-4 mb-10'}>
                        <div className={'container pt-6'}>
                            <p className={'text-4xl text-gray-700 text-center mb-6'}>Это удобно</p>
                            <div className={'flex flex-col lg:flex-row pt-5'}>
                                <div className={'flex flex-row items-center justify-center basis-1/3 my-4'}>
                                    <FaClock className={'mr-4'} size={48} color={Colors.coloredIcon}/>
                                    <p className={'text-xl'}>Не тратьте время на поиск места</p>
                                </div>
                                <div className={'flex flex-row items-center justify-center basis-1/3 my-4'}>
                                    <SiAdguard className={'mr-4'} size={48} color={Colors.coloredIcon}/>
                                    <p className={'text-xl'}>Охраняемые парковки</p>
                                </div>
                                <div className={'flex flex-row items-center justify-center basis-1/3 my-4'}>
                                    <HiCursorClick className={'mr-4'} size={48} color={Colors.coloredIcon}/>
                                    <p className={'text-xl'}>Арендуйте в два клика</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'flex justify-center m-4 mt-10'}>
                        <div className={'container pt-8'}>
                            <p className={'text-4xl text-gray-700 text-center mb-10'}>Как это работает</p>
                            <div className={'flex flex-col lg:flex-row pt-6 mb-5'}>
                                <div className={'flex flex-col items-center justify-center basis-1/3'}>
                                    <IoSearchSharp size={64} color={Colors.coloredIcon} />
                                    <p className={'text-xl text-center my-5'}>1. Найдите парковку</p>
                                </div>
                                <div className={'flex flex-col items-center justify-center basis-1/3'}>
                                    <FaRegBookmark size={64} color={Colors.coloredIcon} />
                                    <p className={'text-xl text-center my-5'}>2. Забронируйте и оплатите</p>
                                </div>
                                <div className={'flex flex-col items-center justify-center basis-1/3'}>
                                    <LuParkingSquare size={64} color={Colors.coloredIcon} />
                                    <p className={'text-xl text-center my-5'}>3. Приезжайте и паркуйтесь</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
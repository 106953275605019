import {useNavigate, useParams} from "react-router";
import {useEffect, useState} from "react";
import usePaymentStore from "../../../store/usePaymentStore";
import {errorToast} from "../../../components/toasts/error";
import Loading from "../../../components/loading";
import {MAIN_ROUTE} from "../../../components/routes/routeConsts";

function PaymentPage() {
    const {id} = useParams()
    const {payment, getPayment} = usePaymentStore()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        getPayment(id)
            .catch(e => {
                console.log(e)
                if (e.data) {
                    errorToast(e.data.ex)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    const handleReturnToHome = (e) => {
        e.preventDefault()
        navigate(MAIN_ROUTE)
    }

    if (loading && !payment) {
        return <Loading />
    }

    if (!loading && !payment) {
        return <div>Не найдено. Попробуйте перезагрузить страницу или вернитесь на <a href={'#'} onClick={handleReturnToHome}>Главную</a></div>
    }

    return (
        <div className={'p-4 lg:ml-10'}>
            <p className={'text-2xl'}>Оплатите парковочное место по следующим реквизитам:</p>
            <p className={'text-xl'}>Чек придет на Вашу электронную почту</p>
        </div>
    )
}

export default PaymentPage
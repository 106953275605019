import ReactDOM from 'react-dom';
import App from "./App";
import {ToastContainer} from "react-toastify";
import './index.css';


ReactDOM.render(
    <>
        <App/>
        <ToastContainer/>
    </>
,
    document.getElementById('root')
)
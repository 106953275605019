export const MAIN_ROUTE = '/cabinet'

// ----- v2 ------
export const ID_ROUTE = '/id'
export const LOGIN_ROUTE = '/login'
export const SIGNUP_ROUTE = '/signup'
export const FORGOT_PASSWORD = '/forgot-password'
export const TELEGRAM_LOGIN = '/login/telegram'
// ----- id ------

export const EXPLORE = ID_ROUTE + '/explore'
export const PROFILE = ID_ROUTE + '/profile'
export const SUBSCRIPTION = ID_ROUTE + '/subscription'

export const LOCATION = ID_ROUTE + '/location'
// ----- v3 ------

export const PROFILE_ROUTE = '/profile'

// ----- profile ------
export const BOOKINGS = `${PROFILE_ROUTE}/bookings`
export const USER = `${PROFILE_ROUTE}/user`
export const SEARCH = `${PROFILE_ROUTE}/explore`
export const PAYMENT = `${PROFILE_ROUTE}/payment`
export const SUPPORT = `/support`

export const PRIVACY_ROUTE = '/privacy'
export const TERMS_ROUTE = '/terms-of-use'

import {CiBookmark, CiCircleQuestion, CiLogin, CiSearch, CiUser} from "react-icons/ci";
import {
    BOOKINGS,
    EXPLORE,
    LOGIN_ROUTE,
    PROFILE,
    SEARCH,
    SUBSCRIPTION,
    SUPPORT,
    USER
} from "../components/routes/routeConsts";
import {IoIosHelp, IoIosHelpCircleOutline} from "react-icons/io";
import {IoHelp} from "react-icons/io5";

const tabs = [
    {icon: CiCircleQuestion, text: 'Поддержка', route: SUPPORT }
]

export const authTabs = [
    [
        {icon: CiBookmark, text: 'Бронирования', route: BOOKINGS},
        {icon: CiSearch, text: 'Поиск парковок', route: SEARCH}
    ],
    [
        {icon: CiUser, text: 'Личные данные', route: USER}
    ],
    tabs
]

export const publicTabs = [
    [
        {icon: CiLogin, text: 'Вход или регистрация', route: LOGIN_ROUTE}
    ],
    tabs
]
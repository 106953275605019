import {useEffect, useState} from "react";
import useLocationStore from "../../../store/useLocationStore";
import Loading from "../../../components/loading";
import {SEARCH} from "../../../components/routes/routeConsts";
import {useNavigate} from "react-router";

function LocationCard({location}) {
    const navigate = useNavigate()

    const handleClickLocation = () => {
        navigate(`${SEARCH}/${location.id}`)
    }

    return (
        <div onClick={handleClickLocation} className={'cursor-pointer hover:shadow-xl duration-300 my-4 p-4 shadow-thesis rounded-xl min-w-80'}>
            <div className={'flex flex-row items-center'}>
                <div className={'w-24 mr-4'}>
                    <img className={'object-cover rounded-lg w-24'} alt={location.name} src={location.image_urls[0]} />
                </div>
                <div>
                    <p className={'font-medium text-purple-600'}>{location.name}</p>
                    <p className={'text-sm'}>{location.address}</p>
                    {location.cost === "0" ? '' : <p className={'text-sm'}>{location.cost} руб/мес</p>}
                </div>
            </div>
        </div>
    )
}

export default function SearchPage() {
    const [loading, setLoading] = useState(false)
    const {locations, getLocations} = useLocationStore()

    useEffect(() => {
        setLoading(true)
        if (!locations) {
            getLocations()
                .then(r => {
                    console.log(locations)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
        setLoading(false)
    }, [])

    if (loading || !locations) {
        return <Loading/>
    }

    return (
        <div className={'p-4 md:ml-10'}>
            <p className={'text-2xl'}>Поиск парковок</p>
            <div>
                {locations && locations.map(loc => (
                    <LocationCard location={loc}/>
                ))}
            </div>
        </div>
    )
}
import {BOOKINGS, FORGOT_PASSWORD, SIGNUP_ROUTE, TELEGRAM_LOGIN} from "../components/routes/routeConsts"
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {toast} from "react-toastify";
import useAuthStore from "../store/useAuthStore";
import {ImSpinner2} from "react-icons/im";
import Footer from "../components/footer";
import {FaTelegramPlane} from "react-icons/fa"

export default function LoginPage() {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const {login, accessToken} = useAuthStore()
    const [fields, setFields] = useState({
        email: '',
        password: ''
    })

    useEffect(() => {
        if (accessToken) {
            navigate(BOOKINGS)
        }
    }, [])

    const handleFormChange = (text, field) => {
        setFields({...fields, [field]: text})
    }

    const handleLogoPressed = (e) => {
        e.preventDefault()
        navigate('/')
    }

    const handleSignupClick = (e) => {
        e.preventDefault()
        navigate(SIGNUP_ROUTE)
    }

    const handleForgetPassword = (e) => {
        e.preventDefault()
        navigate(FORGOT_PASSWORD)
    }

    const handleTelegramLogin = () => {
        navigate(TELEGRAM_LOGIN)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)

        login({email: fields.email, password: fields.password})
            .then(() => {
                toast.success('Успешный вход', {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
                navigate(BOOKINGS)
            })
            .catch((error) => {
                toast.error('Ошибка: ' + error?.response?.data?.ex, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                console.log(error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <div className={'flex flex-col justify-center items-center min-h-screen'}>
            <div className={'flex flex-col items-center mb-2 w-80'}>
                <a href={'#'} onClick={handleLogoPressed} className={'text-4xl font-bold text-purple-500'}>Тезис</a>
                <p className={'text-lg'}>Войдите в аккаунт</p>
                <button onClick={handleTelegramLogin} className={'bg-[#0088cc] rounded-md my-2 text-white p-3 w-full flex flex-row justify-center items-center'}>
                    <FaTelegramPlane size={24} className={'mr-2'} />
                    <p className={'font-medium'}>По номеру телефона</p>
                </button>
            </div>
            <form className={'w-80'}>
                <div>
                    <label>
                        <p>Email:</p>
                        <input type={'email'}
                               onChange={(e) => handleFormChange(e.target.value, 'email')}
                               className={'border border-slate-300 p-2 duration-300 focus:shadow-xl rounded-lg w-full focus:border-purple-500 outline-none'}/>
                    </label>
                </div>
                <div className={'mt-2'}>
                    <label>
                        <p>Пароль:</p>
                        <input type={'password'}
                               onChange={(e) => handleFormChange(e.target.value, 'password')}
                               className={'border border-slate-300 p-2 duration-300 focus:shadow-xl rounded-lg w-full focus:border-purple-500 outline-none'}/>
                    </label>
                </div>
                <div className={'mt-4 flex justify-center'}>
                    <button onClick={handleSubmit}
                            className={'border bg-purple-500 rounded-lg w-full p-3'}>
                        <div className={'flex justify-center items-center'}>
                            {isLoading ? <ImSpinner2 size={24} color={'white'} className={'animate-spin'}/> :
                                <p className={'text-white font-medium'}>Войти в аккаунт</p>}
                        </div>
                    </button>
                </div>
                <div className={'flex flex-col items-center'}>
                    <button onClick={handleTelegramLogin} className={'mt-2 w-full border border-purple-500 bg-white rounded-xl p-3'}>Регистрация</button>
                    <a className={'my-5'} href={'#'} onClick={handleForgetPassword}>Забыли пароль?</a>
                </div>
            </form>
            <Footer />
        </div>
    )
}
import {createWithEqualityFn} from "zustand/traditional"
import InputMask from "react-input-mask"
import {useFormik} from "formik"
import * as Yup from "yup"
import {useNavigate} from "react-router"
import {ImSpinner2} from "react-icons/im"
import {useEffect, useState} from "react"
import api from "../http/AutoHttp"
import {cleanPhone} from "../utils/dataFormatter"
import {FaTelegramPlane} from "react-icons/fa"
import {BOOKINGS} from "../components/routes/routeConsts"
import useAuthStore from "../store/useAuthStore"
import {toast} from "react-toastify"
import Footer from "../components/footer"

export function TelegramLoginPage() {
    const navigate = useNavigate()
    const [step, setStep] = useState("PHONE FORM")
    const [showBot, setShowBot] = useState(false)
    const [showCode, setShowCode] = useState(false)
    const {loginTelegram, accessToken} = useAuthStore()

    useEffect(() => {
        if (accessToken) {
            navigate(BOOKINGS)
        }
    }, [])

    const handleLogoPressed = (e) => {
        e.preventDefault()
        navigate('/')
    }

    const checkPhone = async (phone) => {
        const response = await api.get(`/auth/request-code?phone=${phone}`)
        if (response.status === 200) {
            setShowCode(true)
        } else if (response.status === 400) {
            setShowBot(true)
        }
    }

    const formik = useFormik({
        initialValues: {
            phone: ''
        },
        validationSchema: Yup.object({
            phone: Yup.string().trim()
                .min(16, 'Введите телефон полностью')
                .matches(/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/, 'Неверный формат')
                .required('Обязательное поле')
        }),
        onSubmit: (values, {setSubmitting}) => {
            setSubmitting(true)
            console.log('phone equals:' + values.phone)
            const phone = cleanPhone(values.phone)
            console.log('formatted phone:' + phone)
            checkPhone(phone)
            setSubmitting(false)
        }
    })

    const codeFormik = useFormik({
        initialValues: {
            code: ''
        },
        onSubmit: (values, {setSubmitting}) => {
            setSubmitting(true)

            loginTelegram({phone: cleanPhone(formik.values.phone), code: values.code})
                .then(() => {
                    toast.success('Успешный вход', {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    })
                    navigate(BOOKINGS)
                })
                .catch((error) => {
                    toast.error('Ошибка: ' + error?.response?.data?.ex, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    })
                    console.log(error)
                })
                .finally(() => {
                    setSubmitting(false)
                })
        }
    })

    const handleBotPressed = () => {
        window.open('https://t.me/ThesisLoginBot', '_blank')
        setShowCode(true)
        setShowBot(false)
    }

    return (
        <div className={'flex flex-col justify-center items-center min-h-screen'}>
            <div className={'flex flex-col items-center mb-2 w-56'}>
                <a href={'#'} onClick={handleLogoPressed} className={'text-4xl font-bold text-purple-500'}>Тезис</a>
                <p className={'text-lg'}>Введите номер телефона</p>
            </div>

            <form onSubmit={formik.handleSubmit} className={'w-56'}>
                <div className={'mt-2 flex flex-col items-center justify-center'}>
                    <label>
                        <InputMask id={'phone'}
                                   type={'phone'}
                                   value={formik.values.phone}
                                   mask={'+7 (999) 999-99-99'}
                                   alwaysShowMask={true}
                                   onChange={formik.handleChange}
                                   onBlur={formik.handleBlur}
                                   className={'border border-slate-300 p-2 duration-300 text-center focus:shadow-xl rounded-lg w-56 focus:border-purple-500 outline-none'}/>
                        {formik.touched.phone && formik.errors.phone ? (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.phone}</div>
                        ) : null}
                    </label>
                </div>
                <div className={`mt-4 flex justify-center ${showBot || showCode ? 'hidden' : 'block'}`}>
                    <button type={'submit'} disabled={!formik.isValid || formik.isSubmitting}
                            className={'border bg-purple-500 rounded-lg w-full p-3 disabled:bg-purple-100'}>
                        <div className={'flex justify-center items-center'}>
                            {formik.isSubmitting ?
                                <ImSpinner2 size={24} color={'white'} className={'animate-spin'}/> :
                                <p className={'text-white font-medium'}>Далее</p>}
                        </div>
                    </button>
                </div>
            </form>
            <div
                className={`${showBot === true ? 'opacity-100 block' : 'opacity-0 hidden'} transition-opacity ease-in-out duration-500 mt-3`}>
                <div className={'w-56'}>
                    <p className={'italic text-center'}>Отправьте боту /start и следуйте инструкции.</p>
                    <button onClick={handleBotPressed}
                            className={'bg-[#0088cc] rounded-md my-2 text-white p-3 w-full flex flex-row justify-center items-center'}>
                        <FaTelegramPlane size={24} className={'mr-2'}/>
                        <p className={'font-medium'}>Перейти к боту</p>
                    </button>
                </div>
            </div>
            <div
                className={`${showCode === true ? 'opacity-100 block' : 'opacity-0 hidden'} transition-opacity ease-in-out duration-500 my-2 w-56`}>
                <form onSubmit={codeFormik.handleSubmit} className={'w-56'}>
                    <div className={'flex flex-col items-center justify-center'}>
                        <p className={'italic mr-2'}>Код:</p>
                        <InputMask id={'code'}
                                   type={'code'}
                                   value={codeFormik.values.code}
                                   mask={'9999'}
                                   alwaysShowMask={false}
                                   onChange={codeFormik.handleChange}
                                   onBlur={codeFormik.handleBlur}
                                   className={'border border-slate-300 p-2 duration-300 focus:shadow-xl text-center rounded-lg w-full focus:border-purple-500 outline-none'}/>
                        {codeFormik.touched.code && codeFormik.errors.code ? (
                            <div className="text-red-500 text-sm mt-1">{codeFormik.errors.code}</div>
                        ) : null}
                    </div>
                    <div className={'mt-4 flex flex-col justify-center'}>
                        <button type={'submit'} disabled={!codeFormik.isValid || codeFormik.isSubmitting}
                                className={'border bg-purple-500 rounded-lg w-full p-3 disabled:bg-purple-100'}>
                            <div className={'flex justify-center items-center'}>
                                {codeFormik.isSubmitting ?
                                    <ImSpinner2 size={24} color={'white'} className={'animate-spin'}/> :
                                    <p className={'text-white font-medium'}>Войти</p>}
                            </div>
                        </button>
                        <button onClick={handleBotPressed}
                                className={'bg-[#0088cc] rounded-md my-2 text-white p-3 w-full flex flex-row justify-center items-center'}>
                            <FaTelegramPlane size={24} className={'mr-2'}/>
                            <p className={'font-medium'}>Перейти к боту</p>
                        </button>
                    </div>
                </form>
            </div>
            <div className={'mb-5'}>

            </div>
            <Footer />
        </div>
    )
}
import {ImSpinner2} from "react-icons/im";
import {Colors} from "../utils/colors";

export default function Loading() {
    return (
        <div className={'flex justify-center items-center flex-1'}>
            <div>
                <ImSpinner2 className={'animate-spin'} size={48} color={Colors.coloredIcon} />
            </div>
        </div>
    )
}
import {useEffect, useRef, useState} from "react";
import useAuthStore from "../store/useAuthStore";
import {AiOutlineMenu} from "react-icons/ai";
import {Colors, getColor} from "../utils/colors";
import {useNavigate} from "react-router";
import {CiLogout} from "react-icons/ci";
import {authTabs, publicTabs} from "../utils/config";
import Loading from "./loading";

export const Tab = ({tab, index, isColored}) => {
    const navigate = useNavigate()

    const handleTabClick = (e) => {
        e.preventDefault()
        navigate(tab.route)
    }

    return (
        <a key={index} onClick={handleTabClick} href={'#'}
           className={'block rounded-lg p-3 text-gray-800 hover:bg-gray-100 flex items-center'}>
            <tab.icon size={24} color={getColor('tabIcon', isColored)} className={'mr-2'}/>
            {tab.text}
        </a>
    )
}

const AuthenticatedDropdown = () => {
    const {user, getUser, logout} = useAuthStore()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        if (!user) {
            getUser()
                .finally(() => {
                    setLoading(false)
                })
        }
        setLoading(false)
    }, [])

    const handleLogoutClicked = () => {
        logout().then(r => console.log('Successfully logged out'))
    }

    return (
        <div className={'z-30 bg-white absolute right-0 mt-2 border w-64 rounded-lg shadow-lg divide-y'}>
            {(loading || !user)
                ? (
                    <>
                        <div className={'px-3 py-5 flex justify-between'}>
                            <Loading/>
                        </div>
                    </>
                )
                : (
                    <>
                        <div className={'px-3 py-5 flex justify-between'}>
                            <p>{user.firstname}</p>
                            <button onClick={handleLogoutClicked}>
                                <CiLogout size={24} color={Colors.icon} isColored={false}/>
                            </button>
                        </div>
                        {authTabs.map(tabs => (
                            <div>
                                {tabs.map((tab, index) => (
                                    <Tab tab={tab} index={index}/>
                                ))}
                            </div>
                        ))}
                    </>
                )
            }

        </div>
    )
}

const PublicDropdown = () => {
    const {logout} = useAuthStore()

    return (
        <div className={'z-30 bg-white absolute right-0 mt-2 border w-64 rounded-lg shadow-lg divide-y'}>
            {publicTabs.map(tabs => (
                <div>
                    {tabs.map((tab, index) => (
                        <Tab tab={tab} index={index} isColored={true}/>
                    ))}
                </div>
            ))}
        </div>
    )
}

export default function Navigation({isColored}) {
    const [isDropdownOpen, setDropdownOpen] = useState(false)
    const dropdownRef = useRef(null);
    const {accessToken} = useAuthStore()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        document.addEventListener('mousedown', handleClickedOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickedOutside)
        }
    }, [])

    const dropdown = () => {
        if (accessToken) {
            return <AuthenticatedDropdown/>
        }
        return <PublicDropdown/>
    }

    const handleClickedOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false)
        }
    }

    const onBurgerClicked = () => {
        setDropdownOpen(!isDropdownOpen)
    }

    const handleLogoPressed = (e) => {
        e.preventDefault()
        navigate('/')
        // переход на главную страницу
    }

    return (
        <nav
            className={`${getColor('bg', isColored)} ${getColor('navigationShadow', isColored)} p-4 h-20 flex justify-center z-10 fixed w-full`}>
            <div className={'relative container flex justify-between items-center'}>
                <a onClick={handleLogoPressed} href={'#'}
                   className={`text-3xl font-bold ${getColor('headingText', isColored)}`}>Тезис</a>
                <div ref={dropdownRef}>
                    <button onClick={onBurgerClicked}
                            className={`p-2 border rounded-xl ${getColor('hoverBg', isColored)} ${getColor('activeBg', isColored)}`}>
                        <AiOutlineMenu size={24} color={getColor('icon', isColored)}/>
                    </button>
                    {isDropdownOpen && dropdown()}
                </div>
            </div>
        </nav>
    );
}
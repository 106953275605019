import axios from "axios";
import {AUTO_API} from "../utils/apiConsts";

export function CheckAutoServer() {
    return new Promise((resolve, reject) => {
        axios.get(AUTO_API + '/health')
            .then((response) => {
                if (response.status === 200) {
                    resolve()
                }
                reject()
            }).catch((error) => {
                reject(error)
            })
    })

}
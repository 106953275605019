import {createWithEqualityFn} from "zustand/traditional";
import api from "../http/AutoHttp";
import {GET_ALL_LOCATIONS, LOCATIONS} from "../utils/apiConsts";

const initialState = {
    locations: null
}

const useLocationStore = createWithEqualityFn((set, get) => {
    return {
        ...initialState,

        getLocations: async () => {
            return new Promise((resolve, reject) => {
                api.get(LOCATIONS)
                    .then(response => {
                        set(state => ({
                            ...state,
                            locations: response.data
                        }))
                        resolve(response.data)
                    })
                    .catch(e => {
                        reject(e)
                    })
            })
        },

        getLocation: async (id) => {
            return new Promise((resolve, reject) => {
                api.get(`${LOCATIONS}?id=${id}`)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch(e => {
                        console.warn(e)
                        reject(e)
                    })
            })
        }
    }
})

export default useLocationStore
import {Outlet, useLocation, useNavigate} from "react-router";
import {useEffect, useState} from "react";
import {PROFILE_ROUTE, SEARCH} from "../../../components/routes/routeConsts";
import Navigation, {Tab} from "../../../components/navigation";
import Footer from "../../../components/footer";
import {CiLogout} from "react-icons/ci";
import {Colors} from "../../../utils/colors";
import {authTabs} from "../../../utils/config";
import useAuthStore from "../../../store/useAuthStore";
import Loading from "../../../components/loading";

export default function ProfileLayout() {
    const navigate = useNavigate()
    const {pathname} = useLocation()
    const {user, getUser, logout} = useAuthStore()
    const [loading, setLoading] = useState(false)

    const handleLogoutClicked = () => {
        logout().then(r => console.log('Successfully logged out'))
    }

    useEffect(() => {
        if (pathname === PROFILE_ROUTE) {
            navigate(SEARCH)
        }

        setLoading(true)
        if (!user) {
            getUser()
                .finally(() => {
                    setLoading(false)
                })
        }
        setLoading(false)
    }, [])

    if (loading || !user) {
        return <Loading />
    }

    return (
        <div className={'min-h-screen flex flex-col items-center'}>
            <Navigation isColored={false}/>
            <div className={'flex-1 container pl-5 pt-20'}>
                <div className={'flex mt-10 justify-center md:justify-start'}>
                    <div className={'bg-white mt-2 border w-64 rounded-lg divide-y hidden md:block h-fit'}>
                        <div className={'px-3 py-5 flex justify-between'}>
                            <p>{`${user.firstname}`}</p>
                            <button onClick={handleLogoutClicked}>
                                <CiLogout size={24} color={Colors.icon} isColored={false}/>
                            </button>
                        </div>
                        {authTabs.map((tabs, index) => (
                            <div key={index}>
                                {tabs.map((tab, index) => (
                                    <Tab tab={tab} index={index} key={index}/>
                                ))}
                            </div>
                        ))}
                    </div>
                    <Outlet/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
import {createWithEqualityFn} from "zustand/traditional";
import {AUTO_API, AUTH_LOGIN, AUTH_REGISTER, USER_INFO, USER_UPDATE, REFRESH_TOKEN, AUTH_TG} from "../utils/apiConsts"
import api from "../http/AutoHttp";
import axios from "axios";

export const initialState = {
    accessToken: null,
    refreshToken: null,
    user: null
}

const useAuthStore = createWithEqualityFn((set, get) => {

    return {
        ...initialState,

        refreshState: () => {
            set(state => ({
                ...state
            }))
        },

        login: async ({email, password}) => {
            return new Promise((resolve, reject) => {
                api.post(AUTH_LOGIN, {email, password})
                    .then(response => {
                        localStorage.setItem('accessToken', response.data.accessToken)
                        localStorage.setItem('refreshToken', response.data.refreshToken)
                        set(state => ({
                            ...state,
                            accessToken: response.data.accessToken,
                            refreshToken: response.data.refreshToken,
                        }))

                        resolve()
                    })
                    .catch(e => {
                        console.log(e)
                        reject(e)
                    })
            })
        },

        loginTelegram: async ({phone, code}) => {
            return new Promise((resolve, reject) => {
                api.post(AUTH_TG, {Phone: phone, AuthCode: code})
                    .then(response => {
                        localStorage.setItem('accessToken', response.data.accessToken)
                        localStorage.setItem('refreshToken', response.data.refreshToken)
                        set(state => ({
                            ...state,
                            accessToken: response.data.accessToken,
                            refreshToken: response.data.refreshToken,
                        }))

                        resolve()
                    })
                    .catch(e => {
                        console.log(e)
                        reject(e)
                    })
            })
        },

        signup: async ({ firstname, lastname, email, phone, password }) => {
            return new Promise((resolve, reject) => {
                api.post(AUTH_REGISTER, {
                    firstname,
                    lastname,
                    email,
                    phone,
                    password
                })
                    .then(r => {
                        localStorage.setItem('accessToken', r?.data?.accessToken)
                        localStorage.setItem('refreshToken', r?.data?.refreshToken)
                        set(state => ({
                            ...state,
                            accessToken: r.data.accessToken,
                            refreshToken: r.data.refreshToken,
                        }))
                        resolve()
                    })
                    .catch(e => {
                        reject(e)
                    })
            })
        },

        checkAuth: async () => {
            return new Promise((resolve, reject) => {
                const token = localStorage.getItem('accessToken')
                const refreshToken = localStorage.getItem('accessToken')
                if (!token || !refreshToken) {
                    reject()
                }

                api.get(`${AUTO_API}/auth/validate?token=${token}`)
                    .then(response => {
                        if (response.data.tokenValid) {
                            set(state => ({
                                ...state,
                                accessToken: token
                            }))
                            resolve()
                        } else {

                            axios.post(AUTO_API + REFRESH_TOKEN, {}, {
                                headers: {
                                    'Authorization': refreshToken
                                }
                            }).then(response => {
                                const newAccessToken = response.data.accessToken
                                const newRefreshToken = response.data.refreshToken

                                localStorage.setItem('accessToken', newAccessToken)
                                localStorage.setItem('refreshToken', newRefreshToken)

                                set(state => ({
                                    ...state,
                                    accessToken: newAccessToken
                                }))

                                return api.get(`/auth/validate?token=${newAccessToken}`)
                            }).then(revalidateResponse => {
                                if (revalidateResponse.data.tokenValid) {
                                    set(state => ({
                                        ...state,
                                        accessToken: localStorage.getItem('accessToken')
                                    }))
                                    resolve()
                                } else {
                                    localStorage.removeItem('accessToken')
                                    localStorage.removeItem('refreshToken')
                                    reject()
                                }
                            }).catch(e => {
                                localStorage.removeItem('accessToken')
                                localStorage.removeItem('refreshToken')
                                reject()
                            })
                        }
                    })
                    .catch(e => {
                        set(state => ({
                            ...state,
                            accessToken: null
                        }))
                    })
                    .finally(() => {
                        set(state => ({
                            ...state,
                            loading: false
                        }))
                    })
            })
        },

        logout: async () => {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')

            set(initialState)
        },

        getUser: async () => {
            return new Promise((resolve, reject) => {
                api.get(USER_INFO)
                    .then(r => {
                        set(state => ({
                            ...state,
                            user: r.data
                        }))
                        resolve()
                    })
                    .catch(e => {
                        reject(e)
                    })
            })
        },

        updateUser: async (user) => {
            return new Promise((resolve, reject) => {
                api.put(USER_UPDATE, user)
                    .then(r => {
                        if (r.status === 200) {
                            set(state => ({
                                ...state,
                                user: r.data
                            }))
                            resolve()
                        } else {
                            let error = new Error()
                            error.response = r
                            throw error
                        }
                    })
                    .catch(e => {
                        reject(e)
                    })
            })
        }
    }
})

export const logout = useAuthStore.getState().logout
export const refreshState = useAuthStore.getState().refreshState

export default useAuthStore
export default class AcceptProps {
    color = ''
    text = ''

    constructor(acceptStatus) {
        switch (acceptStatus) {
            case 'REVIEW': {
                this.color = 'text-yellow-500'
                this.text = 'Проверяется'
                break
            }
            case 'ACCEPTED': {
                this.color = 'text-green-500'
                this.text = 'Одобрено'
                break
            }
            case 'DECLINED': {
                this.color = 'text-red-400'
                this.text = 'Отклонено'
                break
            }
            default: {
                this.color = 'text-gray-400'
                this.text = 'ошибка'
            }
        }

    }
}
import {createWithEqualityFn} from "zustand/traditional";
import {PAYMENT} from "../utils/apiConsts";
import api from "../http/AutoHttp";

const initialState = {
    payment: null
}

const usePaymentStore = createWithEqualityFn((set, get) => {
    return {
        ...initialState,

        getPayment: async (id) => {
            return new Promise((resolve, reject) => {
                api.get(`${PAYMENT}?id=${id}`)
                    .then(response => {
                        if (response.status === 200) {
                            set(state => ({
                                ...state,
                                payment: response.data
                            }))
                            resolve(response)
                        } else {
                            reject(response)
                        }
                    })
                    .catch(e => {
                        reject(e)
                    })
            })
        }
    }
})

export default usePaymentStore
import useAuthStore from "../../store/useAuthStore"
import {Outlet, useNavigate} from "react-router"
import {LOGIN_ROUTE, USER} from "../../components/routes/routeConsts"
import {useEffect} from "react"
import useSubscriptionStore from "../../store/useSubscriptionStore"
import {toast} from "react-toastify"

function errtoast(msg) {
    toast.error(`Заполните ${msg}`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
    })
}

export default function ProtectedLayout() {
    const {accessToken} = useAuthStore()
    const {getSubscriptions} = useSubscriptionStore()
    const navigate = useNavigate()

    useEffect(() => {
        getSubscriptions()
            .catch(e => {
                if (e.response && e.response.status === 403) {
                    switch (e.response.data.ErrorCode) {
                        case "1488": {
                            console.log("Требуется номер телефона")
                            navigate(USER)
                            errtoast("номер телефона")
                            break
                        }
                        case "1489": {
                            console.log("Требуется email")
                            navigate(USER)
                            errtoast("email")
                            break
                        }
                        default: {
                            console.log(`Неизвестная ошибка`)
                            navigate(USER)
                        }
                    }
                }
            })
    }, [])

    if (!accessToken) {
        navigate(LOGIN_ROUTE)
    }

    return (
        <Outlet/>
    )
}